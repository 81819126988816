import recipes from "./RecipeData";
import styles from './RecipeDetail.module.css';

export default function RecipeDetail({ recipeId }) {
    const recipeDetail = recipes[recipeId];

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>Food:</td>
                        <td>{recipeDetail.name}</td>
                    </tr>
                    <tr>
                        <td>Method:</td>
                        <td>{recipeDetail.method}</td>
                    </tr>
                    <tr>
                        <td>Cooking Time:</td>
                        <td>{recipeDetail.cookingTime}</td>
                    </tr>
                    <tr>
                        <td>Cooking Temp:</td>
                        <td>{recipeDetail.cookingTemp}</td>
                    </tr>
                    {recipeDetail.targetTemp && (
                        <tr>
                            <td>Target Temp:</td>
                            <td>{recipeDetail.targetTemp}</td>
                        </tr>
                    )}
                    <tr>
                        <td>Notes:</td>
                        <td>{recipeDetail.instructions}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}