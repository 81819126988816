import RecipeList from '../components/RecipeList';
import './Home.css';

export default function Home() {
    return (
        <div className="home-container">
            <h1 className="home-title">Welcome to the Recipe App!</h1>
            <RecipeList />
        </div>
    );
}