import RecipeDetail from "../components/RecipeDetail";
import { useParams } from 'react-router-dom';
import './Home.css';

export default function Recipe() {
    const { id } = useParams();
    console.log('Recipes:', id);
    return (
        <div className="home-container">
            <h1 className="home-title">Recipe Details</h1>
            <RecipeDetail recipeId={id} />
        </div>
    );
}