import recipes from "./RecipeData";
import styles from './RecipeList.module.css';

export default function RecipeList() {
    return (
        <div className={styles.listContainer}>
            <ul className={styles.list}>
                {recipes.map((recipe, index) => (
                    <li key={index} className={styles.listItem}>
                        <a href={`/recipe/${index}`}>{recipe.name}</a>
                        <span>{recipe.cookingTime}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}